

















































































































































































































































































































































































































import { Component, Ref, Prop, Vue, Watch } from 'vue-property-decorator'
import type { Venda } from '@/models'
import {	
	Loja,
	Cliente,
	ResumoDaVenda,
	NotaDaVenda,
	FiltroDePedido,
	MotivoCancelamento,
	MotivosCancelamentoFiscal,
} from '@/models'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import SelecaoDePdv from '@/components/venda/SelecaoDePdv.vue'
import moment from 'moment'
import {
	CancelarVendaUseCase,
	FindVendaUseCase,
	FindLojaUseCase,
} from '@/usecases'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import {
	obterCnpjOuCpfDaVenda,
	obterDisplayClienteDaVenda,
	obterTotalDaVenda,
} from '@/shareds/venda-shareds'
import DialogoDeDetalhesDaVenda from '@/components/venda/DialogoDeDetalhesDaVenda.vue'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import {
	aplicarMascaraParaCpfOculto,
	formatarCnpjOuCpf,
	removerFormatacaoDeCnpjOuCpf,
} from '@/shareds/formatadores'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import DialogoDeEdicaoDePedido from '@/components/venda/DialogoDeEdicaoDePedido.vue'
import DialogoDeEdicaoDeColeta from '@/components/venda/DialogoDeEdicaoDeColeta.vue'
import { CancelarNotaFiscalUseCase } from '@/usecases/fiscal/CancelarNotaFiscalUseCase'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { formatarMoeda } from '@/shareds/formatadores'
import IniciarUmaDevolucao from '@/usecases/venda/IniciarUmaDevolucao'
import CamposFiltroDePedidos from '../venda/CamposFiltroDePedidos.vue'
import { obrigatorio } from '@/shareds/regras-de-form'
import RodapePersonalizado from '@/components/ui/data-tables/RodapePersonalizado.vue'
import ConfirmacaoComMotivo from '@/components/ui/ConfirmacaoComMotivo.vue'
import DialogoDeVerificacaoDeProdutos from '@/views/application/venda/DialogoDeVerificacaoDeProdutos.vue'
import mapErrosSefaz from '@/shareds/fiscal/tabelas/mapErrosSefaz'
import ConfirmacaoComMotivoFiscal from '@/components/ui/ConfirmacaoComMotivoFiscal.vue'

const FILTRO_DE_PEDIDOS = 'FILTRO_DE_PEDIDOS'

@Component({
	components: {
		SeletorDeLojasDoUsuario,
		RangeDatePicker,
		DataTableDeCrudPaginado,
		DialogoDeDetalhesDaVenda,
		SelecaoDePdv,
		Confirmacao,
		DialogoDeEdicaoDePedido,
		CamposFiltroDePedidos,
		RodapePersonalizado,
		ConfirmacaoComMotivo,
		DialogoDeVerificacaoDeProdutos,
		DialogoDeEdicaoDeColeta,
		ConfirmacaoComMotivoFiscal,
	},
})
export default class TelaDePedidos extends Vue {
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDePedido
	@Ref() dialogoDeEdicaoDeColeta!: DialogoDeEdicaoDeColeta
	@Ref() dialogoDeFiltros!: CamposFiltroDePedidos
	@Ref() form!: HTMLFormElement
	@Ref() dialogoDeVerificacaoDeProdutos!: DialogoDeVerificacaoDeProdutos
	@Prop() venda!: Venda

	buscando = false
	carregando = false
	totalRegistros = 0
	iniciandoDevolucao = false
	situacoes: string[] = ['Pendente']
	cancelandoPedidos: string[] = []
	cancelandoNotasFiscais: string[] = []
	idsDasLojas: string[] = []
	mostrarTabela = false
	motivoDoCancelamento: MotivoCancelamento | null = null
	deveVerificarProdutos = false
	mapErrosSefaz = mapErrosSefaz
	motivoDoCancelamentoFiscal: MotivosCancelamentoFiscal | null = null

	filtro: FiltroDePedido = localStorage[FILTRO_DE_PEDIDOS]
		? JSON.parse(localStorage[FILTRO_DE_PEDIDOS])
		: {
			lojaId: null,
			cliente: null,
			situacoes: [],
			datas: [],
			identificador: null,
			identificadorExterno: null,
		}

	paginacao = {
		page: 0,
		itemsPerPage: 10,
		itemsLength: 0,
	}
	obrigatorio = obrigatorio
	formatarMoeda = formatarMoeda

	vendas: ResumoDaVenda[] = []
	vendaResumida: ResumoDaVenda[] = []
	loja: Loja | null = null
	cliente: Cliente | null = null
	lojaId!: string
	findVendaUseCase = new FindVendaUseCase()
	cancelarVendaUseCase = new CancelarVendaUseCase()
	findLojaUseCase = new FindLojaUseCase()
	cancelarNotaFiscalUseCase = new CancelarNotaFiscalUseCase()
	iniciarUmaDevolucao = new IniciarUmaDevolucao()

	exigeMotivoDeCancelamento = false

	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	obterDisplayClienteDaVenda = obterDisplayClienteDaVenda

	headers = [
		{ text: 'Identificador', value: 'identificador', sortable: false },
		{
			text: 'Identificador Externo',
			value: 'identificadorExterno',
			sortable: false,
		},
		{ text: 'Loja', value: 'loja', sortable: false },
		{ text: 'PDV', value: 'pontoDeVenda.nome', sortable: false },
		{ text: 'Data', value: 'diplayDataHora', sortable: false },
		{ text: 'Cliente', value: 'displayCliente', width: 150, sortable: false },
		{ text: 'Situação', value: 'displaySituacao', sortable: false },
		{ text: 'NF / Série', value: 'displayNfSerie', sortable: false },
		{ text: 'Último Status', value:'displayStatusDaNota', sortable: false },
		{ text: 'Total (R$)',  value:'displayTotalDaVenda', sortable: false },
		{ text: 'Transporte', value: 'informacoesDeTransporte', align: 'center', sortable: false},
		{ text: 'Ações', value: 'actions', align: 'center' },
	]

	get podeCancelarPedido() {
		return UserLoginStore.permiteRegraDeNegocio('pode-cancelar-pedidos')
	}

	created() {
		this.buscarPagina()
	}

	validaSituacao(item: ResumoDaVenda) {
		return (
			item.situacaoPedido !== 'Pendente' &&
			item.situacaoPedido !== 'Entregue' &&
			item.situacaoPedido !== 'Cancelado' &&
			item.situacaoPedido !== 'Devolvido'
		)
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	get itensFormatados() {
		const itensFiltrados = this.vendas
			.filter(venda => venda.pedido !== null)
			.map(venda => ({
				...venda,
				displayNfSerie: this.displayNfSerie(venda),
				displayCliente: this.displayCliente(venda),
				displaySituacao: this.displaySituacao(venda),
				diplayDataHora: this.diplayDataHora(venda),
				displayStatusDaNota: this.displayStatusDaNota(venda),
				displayTotalDaVenda: this.displayTotalDaVenda(venda),
			}));

		return itensFiltrados;
	}

	displayCliente(venda: ResumoDaVenda) {
		const cnpjOuCpf = obterCnpjOuCpfDaVenda(venda)
		if (!cnpjOuCpf) return ''
		const cnpjOuCpfSemMascara = removerFormatacaoDeCnpjOuCpf(cnpjOuCpf)
		let display =
			cnpjOuCpfSemMascara.length <= 11
				? aplicarMascaraParaCpfOculto(cnpjOuCpfSemMascara)
				: formatarCnpjOuCpf(cnpjOuCpf)
		if (venda.cliente) {
			display = display + ' / ' + venda.cliente.endereco + ' Nome: ' + venda.cliente.razaoSocialOuNome
		}
		return display
	}

	displaySituacao(venda: ResumoDaVenda) {
		const situacaoDoPedido = venda.situacaoPedido
		return situacaoDoPedido
	}

	displayNotasFiscais(venda: ResumoDaVenda): string {
		return venda.notas
			.filter(({ cstat }) => !['101', '102'].includes(cstat))
			.map(nota => `Nota ${nota.nnf} / ${nota.serie}`)
			.join('<br>')
	}

	diplayDataHora(venda: ResumoDaVenda) {
		if (!venda.dataHora) return ''
		return dateTimeToPtBrFormat(venda.dataHora)
	}

	podeCancelar(notas: NotaDaVenda[]) {
		return (
			!notas.length ||
			notas.some(({ cstat }) => cstat !== '101' && cstat !== '102')
		)
	}

	async cancelarVenda(
		venda: ResumoDaVenda,
		motivoDoCancelamento?,
	) {
		if (!venda) {
			AlertModule.setError('Pedido a cancelar não encontrado')
			return
		}

		this.exigirMotivoDeCancelamento(venda.loja || '')
		if (this.exigeMotivoDeCancelamento && !motivoDoCancelamento) {
			AlertModule.setError('Necessário um motivo para cancelamento!')
			return
		}
		/*if (this.prazoCancelamentoDePedido(venda) === false) {
			AlertModule.setError('Sem prazo para cancelamento')
			return
		}*/

		try {
			if (this.cancelandoPedidos.includes(venda.id))
				throw new Error('Pedido já está em cancelamento')

			this.cancelandoPedidos.push(venda.id)

			const params = {
				motivo: motivoDoCancelamento,
			}
			const vendaAtualizada = await this.cancelarVendaUseCase.cancelar(
				venda.id,
				params,
			)

			const vendaResumida = {
				...vendaAtualizada,
				total: obterTotalDaVenda(vendaAtualizada),
				vendaOrigem: undefined,
				chaveDanfeExterna: null,
			} as ResumoDaVenda
			this.vendaResumida = this.vendaResumida.map(venda =>
				venda.id === vendaAtualizada.id ? vendaResumida : venda,
			)
			AlertModule.setSuccess(`Pedido cancelado com sucesso`)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.cancelandoPedidos = this.cancelandoPedidos.filter(
				id => venda.id !== id,
			)
			this.buscarPagina()
		}
	}

	async limparSelecaoDeFiltros() {
		this.filtro = {
			lojaId: null,
			cliente: null,
			identificador: '',
			identificadorExterno: '',
			situacoes: [],
			datas: [],
			possuiFrete: false,
		}
		this.dialogoDeFiltros.ocultar()
		this.mostrarTabela = false
		this.buscarPagina()
	}

	abrirFiltros() {
		this.dialogoDeFiltros.mostrar()
	}

	async cancelarNotasFiscais(
		venda: ResumoDaVenda,
		motivoDoCancelamentoFiscal: string,
	) {
		if (!venda.notas.length) {
			AlertModule.setError('Venda não possui notas fiscais emitidas')
			return
		}

		if (this.podeCancelarVenda(venda) === false) {
			AlertModule.setError('Sem prazo para cancelamento')
			return
		}

		this.cancelandoNotasFiscais.push(venda.id)

		if (!motivoDoCancelamentoFiscal) {
			AlertModule.setError('Necessário um motivo para cancelamento fiscal')
			return
		}

		try {
			const vendaAtualizada = await this.cancelarNotaFiscalUseCase.execute(
				venda.id,
				motivoDoCancelamentoFiscal,
			)

			const vendaResumida = {
				...vendaAtualizada,
				total: obterTotalDaVenda(vendaAtualizada),
				vendaOrigem: undefined,
				chaveDanfeExterna: null,
			} as ResumoDaVenda
			this.vendaResumida = this.vendaResumida.map(venda =>
				venda.id === vendaAtualizada.id ? vendaResumida : venda,
			)
			const notasList = vendaAtualizada.notas
				.map(nota => `Nota ${nota.nnf} / ${nota.serie} cancelada com sucesso`)
				.join('<br>')
			AlertModule.setSuccess({
				text: notasList,
				timeout: -1,
			})
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.buscarPagina()
			this.cancelandoNotasFiscais = this.cancelandoNotasFiscais.filter(
				id => venda.id !== id,
			)
		}
	}

	async devolverPedido(venda: Venda) {
		if (!venda) {
			AlertModule.setError('Venda inválida')
			return
		}

		try {
			this.iniciandoDevolucao = true
			if (VendaModule.turnoDeVenda?.id) {
				if (
					VendaModule.turnoDeVenda.pontoDeVenda.loja.id !==
					venda.pontoDeVenda?.loja.id
				)
					throw Error(
						'Turno de venda em aberto não pertence a mesma loja do pedido!',
					)
			}
			this.$router.push({
				path: '/caixa',
				query: {
					voltarPraPedido: 'true',
					identificador: venda.identificador,
					devolucao: 'true',
				},
			})
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.iniciandoDevolucao = false
		}
	}

	async buscarPagina() {
		try {
			this.mostrarTabela = true
			this.buscando = true
			const pagina = await this.findVendaUseCase.list({
				dataHoraInicial:
					this.filtro.datas[0] && this.filtro.datas[1]
						? moment(this.filtro.datas[0]).toISOString(true)
						: undefined,
				dataHoraFinal:
					this.filtro.datas[0] && this.filtro.datas[1]
						? moment(`${this.filtro.datas[1]} 23:59:59 `).toISOString(true)
						: undefined,
				lojaId: this.filtro.lojaId || undefined,
				page: this.paginacao.page,
				size: this.paginacao.itemsPerPage,
				cliente:
					removerFormatacaoDeCnpjOuCpf(
						(typeof this.filtro.cliente === 'string'
							? this.filtro.cliente
							: this.filtro.cliente?.cnpjOuCpf) || null,
					) || undefined,
				vendasComPedido: true,
				identificador: this.filtro.identificador || undefined,
				possuiFrete: this.filtro.possuiFrete,
				identificadorExterno: this.filtro.identificadorExterno || undefined,
				situacoesDoPedido:
					this.filtro.situacoes.length > 0
						? this.filtro.situacoes.join(',')
						: this.filtro.situacoes,
				sort: 'dataHora,desc',
			})

			if (this.filtro.lojaId) {
				const lojaDosPedidos = await this.findLojaUseCase.findLojaById(
					this.filtro.lojaId,
				)
				this.deveVerificarProdutos =
					lojaDosPedidos.configuracaoDaLoja.exigirVerificacaoDeProdutos
			} else {
				const idsLoja = pagina.content.map(venda => venda.pontoDeVenda.loja.id)

				for (const ids of idsLoja) {
					if (!this.idsDasLojas.includes(ids)) {
						this.idsDasLojas.push(ids)
					}
				}

				const promises = pagina.content.map(async venda => {
					const lojaId = venda.pontoDeVenda.loja.id
					let deveVerificar

					if (this.idsDasLojas.includes(lojaId)) {
						deveVerificar = true
					} else {
						const lojaDosPedidos = await this.findLojaUseCase.findLojaById(
							lojaId,
						)
						deveVerificar =
							lojaDosPedidos.configuracaoDaLoja.exigirVerificacaoDeProdutos
					}

					this.deveVerificarProdutos = deveVerificar
				})

				await Promise.all(promises)
			}

			this.vendas = pagina.content
			this.totalRegistros = pagina.totalElements
			this.paginacao.itemsLength = pagina.totalElements
		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.buscando = false
			this.idsDasLojas.splice(0, this.idsDasLojas.length)
			this.dialogoDeFiltros.ocultar()
		}
	}

	podeCancelarVenda(venda: ResumoDaVenda) {
		if (venda.notas.map(nota => nota.cstat === '0')) return
		const converterDhNotaParaMoment = (dh: string) =>
			moment(dh.replace('T', ' ').split('.')[0])
		const notasOrdenadas = [...venda.notas].sort((notaA, notaB) =>
			converterDhNotaParaMoment(notaA.dhEmi).diff(
				converterDhNotaParaMoment(notaB.dhEmi),
			),
		)
		const ultimaNota = notasOrdenadas[notasOrdenadas.length - 1]
		if (ultimaNota.cstat === '101' || ultimaNota.cstat === '102') return false
		const dhEmi = converterDhNotaParaMoment(ultimaNota.dhEmi)
		return moment().diff(dhEmi, 'hours') < 24
	}

	/*prazoCancelamentoDePedido(venda: ResumoDaVenda) {
		const dhVenda = moment(venda.dataHora?.toString().replace('T', ' ').split('.')[0])
		const dhAtual = moment(new Date().toISOString().replace('T', ' ').split('.')[0])

		const prazo = dhAtual.diff(dhVenda, 'hours')

		if (prazo >= 24) return false
	}*/

	@Watch('filtro', { deep: true })
	onChangeFiltro(newFiltro) {
		const stringified = JSON.stringify(newFiltro)
		localStorage.setItem(FILTRO_DE_PEDIDOS, stringified)
	}

	async atualizarStatusDoPedido(vendaAtualizada: ResumoDaVenda) {
		try {
			const venda = await this.findVendaUseCase.get(vendaAtualizada.id)

			const vendaResumida = {
				...vendaAtualizada,
				vendaOrigem:
					vendaAtualizada.vendaOrigem === undefined
						? ''
						: vendaAtualizada.vendaOrigem,
				situacaoPedido: vendaAtualizada.pedido?.situacao,
				notas: venda.notas,
			} as ResumoDaVenda

			const indice = this.vendas.findIndex(
				venda => vendaAtualizada.id === venda.id,
			)
			if (indice === -1) return

			this.vendas.splice(indice, 1, vendaResumida)
		} catch (e) {
			AlertModule.setError(
				'Erro ao atualizar o status na tela, comunique o suporte' + e,
			)
		}
	}

	
	async atualizarTotalDoPedido(vendaAtualizada: ResumoDaVenda) {
		try {
			const vendaResumida = {
				...vendaAtualizada,
				vendaOrigem:
					vendaAtualizada.vendaOrigem === undefined
						? ''
						: vendaAtualizada.vendaOrigem,
				total: vendaAtualizada.total,
			} as ResumoDaVenda

			const indice = this.vendas.findIndex(
				venda => vendaAtualizada.id === venda.id,
			)
			if (indice === -1) return

			this.vendas.splice(indice, 1, vendaResumida)
		} catch (e) {
			AlertModule.setError(
				'Erro ao atualizar o total na tela, comunique o suporte' + e,
			)
		}
	}

	async exigirMotivoDeCancelamento(lojaId: string) {
		const lojaDosPedidos = await this.findLojaUseCase.findLojaById(lojaId)

		return (this.exigeMotivoDeCancelamento =
			lojaDosPedidos.configuracaoDaLoja.exigeMotivoDeCancelamento)
	}

	atualizarPagina(page) {
		this.paginacao.page += page
		this.buscarPagina()
	}

	displayNfSerie(venda: ResumoDaVenda) {
		const nota = venda.notas[0]
		return nota
			? `${nota.modelo} ${nota.nnf} / ${nota.serie}`
			: ''
	}

	displayStatusDaNota(venda: ResumoDaVenda) {
		const nota = venda.notas[0]
		return nota && mapErrosSefaz[nota.cstat]
			? nota.cstat + ' - ' + mapErrosSefaz[nota.cstat]
			: nota?.cstat || ''
	}

	displayTotalDaVenda(venda: ResumoDaVenda) {
		if (!venda.total) return '0,00'
		return formatarMoeda(venda.total)
	}


	podeDevolverPedido() {
		const permissoes = UserLoginStore.perfil?.permissoes
		for (const perimssao of permissoes!) {
			if (perimssao.includes("pode-devolver-pedido")) {
				return true;
			}
		}
		return false;
	}
}
